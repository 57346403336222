import pagination from '@/components/mixins/pagination';

export default {
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [],
    };
  },
  methods: {
    fetchData(params = {}) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('system-logs/list', { ...this.$route.query, ...params })
        .then((res) => {
          const { list, pagination } = res;

          this.list = list.map((p, index) => {
            p._id = index + 1;

            return p;
          });

          if (pagination) {
            this.setPagination(pagination);
          }
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
