<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-input
        v-model:value="name"
        :size="size"
        :placeholder="$t('API Name')"
        @change="delayedFilter"
      />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
        @change="handleFilter"
      >
        <a-input :value="rangeString" :placeholder="$t('Request date')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column">
      <a-button
        v-show="name || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "API Name": "API Name",
    "Clear": "Clear",
    "Request date": "Request date"
  },
  "ja": {
    "API Name": "API名",
    "Clear": "クリア",
    "Request date": "リクエスト日"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SystemLogsSearch',
  components: {
    CalendarFilled,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      name: undefined,
      range: [],
    };
  },
  computed: {
    rangeString() {
      if (this.range.length) {
        const from = this.$filters.date(this.range[0], { format: this.dateFormat });
        const to = this.$filters.date(this.range[1], { format: this.dateFormat });

        return `${from} ~ ${to}`;
      }

      return '';
    },
    formattedRange() {
      if (this.range.length) {
        const dateFormat = 'YYYY-MM-DD';
        const from = this.$filters.date(this.range[0], { format: dateFormat });
        const to = this.$filters.date(this.range[1], { format: dateFormat });

        return `${from},${to}`;
      }

      return undefined;
    },
  },
  mounted() {
    this.name = this.$route.query.name || undefined;
    this.range = this.$route.query.range ? this.$route.query.range.split(',') : [];
  },
  methods: {
    // eslint-disable-next-line func-names
    delayedFilter: debounce(async function () {
      await this.handleFilter();
    }, 600),
    async handleFilter() {
      const query = cleanObject({
        ...this.$route.query,
        name: this.name,
        range: this.formattedRange,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    },
    handleClear() {
      this.name = undefined;
      this.status = undefined;
      this.range = [];
      this.handleFilter();
    },
  },
};
</script>

<style scoped></style>
