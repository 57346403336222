<template>
  <div class="section">
    <div class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Simulation') }}
          </h2>
        </div>
      </div>
      <div class="columns is-mobi">
        <div class="column">
          <Search />
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #error="{ record }">
          {{ record.error || '-' }}
        </template>
        <template #requestDateTime="{ record }">
          {{ $filters.date(record.requestDateTime, { format: $variables.datetimeFormat }) }}
        </template>
        <template #request="{ record }">
          {{ record.request || '-' }}
        </template>
        <template #responseDateTime="{ record }">
          {{ $filters.date(record.responseDateTime, { format: $variables.datetimeFormat }) }}
        </template>
        <template #response="{ record }">
          {{ record.response || '-' }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Simulation": "Simulation",
"API Name": "API Name",
"Error": "Error",
"Request datetime": "Request datetime",
"Request": "Request",
"Response datetime": "Response datetime",
"Response": "Response",
"Delete": "Delete",
"Cancel": "Cancel"
},
"ja": {
"Simulation": "シミュレーション",
"API Name": "API名",
"Error": "エラー",
"Request datetime": "リクエスト日時",
"Request": "リクエスト",
"Response datetime": "応答日時",
"Response": "応答",
"Delete": "削除",
"Cancel": "取り消し"
}
}
</i18n>

<script>
import { CATEGORY } from '@/config/system-logs';
import list from '@/views/system-logs/mixins/list';
import Search from '@/views/system-logs/components/Search';

export default {
  name: 'SystemLogsSimulation',
  components: {
    Search,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('API Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Error'),
          dataIndex: 'error',
          key: 'error',
          slots: { customRender: 'error' },
        },
        {
          title: this.$t('Request datetime'),
          dataIndex: 'requestDateTime',
          key: 'requestDateTime',
          slots: { customRender: 'requestDateTime' },
        },
        {
          title: this.$t('Request'),
          dataIndex: 'request',
          key: 'request',
          width: 320,
          className: 'request-body',
          slots: { customRender: 'request' },
        },
        {
          title: this.$t('Response datetime'),
          dataIndex: 'responseDateTime',
          key: 'responseDateTime',
          slots: { customRender: 'responseDateTime' },
        },
        {
          title: this.$t('Response'),
          dataIndex: 'response',
          key: 'response',
          slots: { customRender: 'response' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'system-logs-simulation') {
        this.fetchLogs();
      }
    },
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    fetchLogs() {
      return this.fetchData({ category: CATEGORY.SIMULATION });
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchLogs();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          console.log(record);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
