<template>
  <div class="section">
    <div class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            Anshin
          </h2>
        </div>
      </div>
      <div class="columns is-mobi">
        <div class="column">
          <Search />
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #requestDateTime="{ record }">
          {{ $filters.date(record.requestDateTime, { format: $variables.datetimeFormat }) }}
        </template>
        <template #user="{ record }">
          {{ getUserName(record) }}
        </template>
        <template #request="{ record }">
          {{ record.request || '-' }}
        </template>
        <template #responseDateTime="{ record }">
          {{ $filters.date(record.responseDateTime, { format: $variables.datetimeFormat }) }}
        </template>
        <template #error="{ record }">
          {{ record.error || '-' }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "User": "User",
    "API Name": "API Name",
    "Request datetime": "Request datetime",
    "Request": "Request",
    "Response datetime": "Response datetime",
    "Response": "Response",
    "Error": "Error",
    "Delete": "Delete",
    "Cancel": "Cancel"
  },
  "ja": {
    "User": "ユーサー",
    "API Name": "API名",
    "Request datetime": "リクエスト日時",
    "Request": "リクエスト",
    "Response datetime": "応答日時",
    "Response": "応答",
    "Error": "エラー",
    "Delete": "削除",
    "Cancel": "取り消し"
  }
}
</i18n>

<script>
import { CATEGORY } from '@/config/system-logs';
import list from '@/views/system-logs/mixins/list';
import Search from '@/views/system-logs/components/Search';

export default {
  name: 'SystemLogsAnshin',
  components: {
    Search,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Request datetime'),
          dataIndex: 'requestDateTime',
          key: 'requestDateTime',
          slots: { customRender: 'requestDateTime' },
        },
        {
          title: this.$t('API Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Request'),
          dataIndex: 'request',
          key: 'request',
          className: 'request-body',
          width: 350,
          slots: { customRender: 'request' },
        },
        {
          title: this.$t('Response datetime'),
          dataIndex: 'responseDateTime',
          key: 'responseDateTime',
          slots: { customRender: 'responseDateTime' },
        },
        {
          title: this.$t('Error'),
          dataIndex: 'error',
          key: 'error',
          slots: { customRender: 'error' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'system-logs-anshin') {
        this.fetchLogs();
      }
    },
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    fetchLogs() {
      return this.fetchData({ category: CATEGORY.ANSHIN });
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchLogs();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          console.log(record);
        },
      };
    },
  },
};
</script>

<style scoped></style>
